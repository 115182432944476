<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <content-header :title-list="titleList"></content-header>
      <div class="from-content-wrap">
        <job-form :id="$route.query.id" :isEdit="true"></job-form>
      </div>
    </div>
  </div>
</template>

<script>
import JobForm from "./components/JobForm";
import ContentHeader from "@/components/Common/ContentHeader";
export default {
  components: { JobForm, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "岗位管理",
          isCur: false,
          link: ""
        },
        {
          text: "编辑",
          isCur: true,
          link: ""
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.g-section {
  background: #f5f5f5;
}
</style>
